// import React from "react"
import styled from "@emotion/styled"

import border from "../images/border.png"

// export default ({ children }) => <PartBackground>{children}</PartBackground>

export default styled.div`
  background: rgb(33, 33, 33);
  margin: 4px 4px;
  padding: 8px;
  border: 10px solid rgb(143, 143, 143);
  border-image: url(${border});
  border-image-slice: 10;
  border-image-repeat: round;
`
