/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import styled from "@emotion/styled"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import TinkerBorder from "./TinkerBorder"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <GutterDiv>
        <main>{children}</main>
        <StyledFooter>
          <TinkerBorder>
            © {new Date().getFullYear()}, Website by Jacob Mikesell
            <br />
            Data and Assets from{" "}
            <StyledA href={"https://github.com/SlimeKnights/TinkersConstruct"}>Tinker's Construct Team</StyledA>
          </TinkerBorder>
        </StyledFooter>
      </GutterDiv>
    </>
  )
}
const StyledA = styled.a`
  color: white;
`
const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
`
const GutterDiv = styled.div`
  width: 95%;

  @media (min-width: 1100px) {
    width: 80%;
  }
  margin: 0px auto;
`

export default Layout
